import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import {
  Input,
  InputGroup,
  Whisper,
  Tooltip,
  Modal,
  Drawer,
  DateRangePicker,
} from "rsuite";
import { bindActionCreators } from "redux";
//circular
import Circularprogressbar from "../../../components/circularprogressbar.js";

// AOS
import AOS from "aos";
import "aos";
import "../../../../node_modules/aos/dist/aos.css";
//apexcharts
import Chart from "react-apexcharts";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

//progressbar
// import Progress from "../../../components/progress.js";
//img
import Empty from "../../../components/Empty/Empty.js";

//Count-up
import CountUp from "react-countup";
// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../../store/setting/setting.js";

import delivery from "../../../restApiService/delivery.js";
import packages from "../../../restApiService/packages.js";
import deliver from "../../../restApiService/auth.js";
import { connect } from "react-redux";

const {
  allowedMaxDays,
  // allowedDays,
  // allowedRange,
  // beforeToday,
  // afterToday,
  // combine,
} = DateRangePicker;

class Index extends Component {
  state = {
    dataList: [],
    selectedItem: [],
    itemDetail: [],
    searchData: [],
    deliverData: 0,
    providerData: 0,
    detailModalIsOpen: false,
    selectedDate: moment(new Date()).format("YYYY-MM-DD"),
    pendingData: 0,
    deletedData: 0,
    currentInprogressCount: 0,
    currentCancelCount: 0,
    currentCustomerUnreachableCount: 0,
    currentDeliverCount: 0,
    currentProviderCount: 0,
    // general stat
    currentTerminedCount: 0,
    globalDeliverCount: 0,
    globalProviderCount: 0,
    globalCancelCount: 0,
    globalInprogressCount: 0,
    globalEndedCoursesCount: 0,
  };

  coursesList() {
    const { token } = this.props.currrentUser;
    delivery.deliveryList(token).then((req) => {
      const pending = req.data.filter(
        (item) => item.status == 1 && item.isCurrent == 1
      );
      const deleted = req.data.filter((item) => item.status == 2);
      this.setState({
        dataList: req.data,
        pendingData: pending.length,
        deletedData: deleted.length,
      });
    });
  }
  deliverList() {
    const { token } = this.props.currrentUser;
    deliver.userInfoList(token).then((req) => {
      const deliver = req.data.filter((item) => item.livoProfilTypeId == 2);
      const provider = req.data.filter((item) => item.livoProfilTypeId == 1);
      this.setState({
        deliverData: deliver.length,
        providerData: provider.length,
      });
    });
  }

  getDeliveryInprogress = (date, type) => {
    const { token } = this.props.currrentUser;
    if (type === 1) {
      packages.packageByStatutAndDate(1, 1, date, token).then((req) => {
        this.setState({
          currentInprogressCount: req.data.length,
        });
      });
    } else {
      packages
        .packageByStatutAndDateRange(1, 1, date[0], date[1], token)
        .then((req) => {
          this.setState({
            globalInprogressCount: req.data.length,
          });
        });
    }
  };

  getDeliveryTermined = (date) => {
    const { token } = this.props.currrentUser;
    packages
      .packageByStatutAndDateRange(5, 0, date[0], date[1], token)
      .then((req) => {
        this.setState({
          currentTerminedCount: req.data.length,
        });
      });
  };

  getDeliveryCancel = (date, type) => {
    const { token } = this.props.currrentUser;
    if (type === 1) {
      packages.packageByStatutAndDate(2, 0, date, token).then((req) => {
        this.setState({
          currentCancelCount: req.data.length,
        });
      });
    } else {
      packages
        .packageByStatutAndDateRange(2, 0, date[0], date[1], token)
        .then((req) => {
          this.setState({
            globalCancelCount: req.data.length,
          });
        });
    }
  };

  getDeliveryCustomerUnreachable = (date, type) => {
    const { token } = this.props.currrentUser;
    if (type === 1) {
      packages.packageByStatutAndDate(3, 0, date, token).then((req) => {
        this.setState({
          currentCustomerUnreachableCount: req.data.length,
        });
      });
    } else {
      packages
        .packageByStatutAndDateRange(3, 0, date[0], date[1], token)
        .then((req) => {
          this.setState({
            globalCustomerUnreachableCount: req.data.length,
          });
        });
    }
  };

  currentDeliverRegistred = (date, type) => {
    const { token } = this.props.currrentUser;
    if (type === 1) {
      deliver.userListByDate(2, date, token).then((req) => {
        this.setState({
          currentDeliverCount: req.data.length,
        });
      });
    } else {
      deliver.userListByDateRange(2, date[0], date[1], token).then((req) => {
        this.setState({
          globalDeliverCount: req.data.length,
        });
      });
    }
  };

  currentProviderRegistred = (date, type) => {
    const { token } = this.props.currrentUser;
    if (type === 1) {
      deliver.userListByDate(1, date, token).then((req) => {
        this.setState({
          currentProviderCount: req.data.length,
        });
      });
    } else {
      deliver.userListByDateRange(1, date[0], date[1], token).then((req) => {
        this.setState({
          globalProviderCount: req.data.length,
        });
      });
    }
  };

  getEndedCourses = (date, type) => {
    const { token } = this.props.currrentUser;
    delivery
      .deliveryByStatutAndDateRange(1, 0, date[0], date[1], token)
      .then((req) => {
        this.setState({
          globalEndedCoursesCount: req.data.length,
        });
        // if (type === 1) {
        //   this.setState({
        //     currentProviderCount: req.data.length,
        //   });
        // } else {
        //   this.setState({
        //     globalProviderCount: req.data.length,
        //   });
        // }
      });
  };

  handleChangeDate = (event) => {
    if (event != null) {
      const startDate = moment(event[0]).format("YYYY-MM-DD");
      const endDate = moment(event[1]).format("YYYY-MM-DD");
      console.log(startDate, endDate);
      const dateArray = [startDate, endDate];
      this.setState({ searchData: dateArray });
      this.getDeliveryTermined(dateArray);
      this.currentDeliverRegistred(dateArray, 2);
      this.currentProviderRegistred(dateArray, 2);
      this.getDeliveryCancel(dateArray, 2);
      this.getDeliveryInprogress(dateArray, 2);
      this.getDeliveryCustomerUnreachable(dateArray, 2);
      this.getEndedCourses(dateArray, 2);
    }
  };

  handleSelectDate = (event) => {
    const value = event.target.value;
    this.setState({ selectedDate: value });
    // general stat
    this.getDeliveryTermined(value);
    this.currentDeliverRegistred(value, 2);
    this.currentProviderRegistred(value, 2);
    this.getDeliveryCancel(value, 2);
    this.getDeliveryInprogress(value, 2);
    this.getDeliveryCustomerUnreachable(value, 2);
    this.getEndedCourses(value, 2);
  };

  componentDidMount() {
    const tdy = moment(new Date()).format("YYYY-MM-DD");
    const ago = moment().subtract(365, "days").format("YYYY-MM-DD");

    // this.coursesList();
    // this.deliverList();
    this.getDeliveryInprogress(tdy, 1);
    this.getDeliveryCancel(tdy, 1);
    this.getDeliveryCustomerUnreachable(tdy, 1);
    this.currentDeliverRegistred(tdy, 1);
    this.currentProviderRegistred(tdy, 1);
    // general stat
    this.getDeliveryTermined([ago, tdy]);
    this.currentDeliverRegistred([ago, tdy], 2);
    this.currentProviderRegistred([ago, tdy], 2);
    this.getDeliveryCancel([ago, tdy], 2);
    this.getDeliveryInprogress([ago, tdy], 2);
    this.getDeliveryCustomerUnreachable([ago, tdy], 2);
    this.getEndedCourses([ago, tdy], 2);
  }

  render() {
    const {
      pendingData,
      deletedData,
      deliverData,
      providerData,
      currentInprogressCount,
      currentCancelCount,
      currentCustomerUnreachableCount,
      currentDeliverCount,
      currentProviderCount,
      currentTerminedCount,
      globalDeliverCount,
      globalProviderCount,
      globalCancelCount,
      globalInprogressCount,
      globalCustomerUnreachableCount,
      globalEndedCoursesCount,
    } = this.state;
    const hideModal = () => {
      this.setState({ detailModalIsOpen: false });
    };
    return (
      <>
        <Row>
          <Col md="12" lg="12">
            <h4 className="mb-4">Les derniers 24H</h4>
          </Col>
          <Col md="12" lg="12">
            <Row className="row-cols-1">
              <div className="overflow-hidden d-slider1 ">
                <Swiper
                  className="p-0 m-0 mb-2 list-inline "
                  slidesPerView={5}
                  spaceBetween={32}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 5 },
                    1500: { slidesPerView: 5 },
                    1920: { slidesPerView: 6 },
                    2040: { slidesPerView: 7 },
                    2440: { slidesPerView: 8 },
                  }}
                >
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#fce152"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={60}
                          id="circle-progress-04"
                        >
                          <svg
                            className=""
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Commandes en cours</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={currentInprogressCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          Linecap="rounded"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          style={{ width: 45, height: 45 }}
                          value={90}
                          id="circle-progress-01"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Commandes annulées</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={currentCancelCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          Linecap="rounded"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          style={{ width: 45, height: 45 }}
                          value={90}
                          id="circle-progress-01"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Client injoignable</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={currentCustomerUnreachableCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#fce152"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={60}
                          id="circle-progress-02"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Livreurs inscrit</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={currentDeliverCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={70}
                          id="circle-progress-03"
                        >
                          <svg className="" width="24" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Fournisseurs inscrit</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={currentProviderCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Row>
          </Col>
          <Col md="12" lg="12">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="mb-4">Statistiques générales</h4>
              {/* <input
                type="date"
                value={this.state.selectedDate}
                className="form-control mb-4 w-15"
                onChange={(e) => this.handleSelectDate(e)}
              /> */}
              <DateRangePicker
                // placeholder="À quelle période ?"
                // disabledDate={allowedMaxDays(365)}
                onChange={this.handleChangeDate}
                format="yyyy-MM-dd"
                className="mb-4 w-15"
              />
            </div>
          </Col>
          <Col md="12" lg="12">
            <Row className="row-cols-1">
              <div className="overflow-hidden d-slider1 ">
                <Swiper
                  className="p-0 m-0 mb-2 list-inline "
                  slidesPerView={5}
                  spaceBetween={32}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                    1500: { slidesPerView: 5 },
                    1920: { slidesPerView: 6 },
                    2040: { slidesPerView: 7 },
                    2440: { slidesPerView: 8 },
                  }}
                >
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#fce152"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={60}
                          id="circle-progress-04"
                        >
                          <svg
                            className=""
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Courses effectuer</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={currentTerminedCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          Linecap="rounded"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          style={{ width: 45, height: 45 }}
                          value={90}
                          id="circle-progress-01"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Livreurs inscrit</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={globalDeliverCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          Linecap="rounded"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          style={{ width: 45, height: 45 }}
                          value={90}
                          id="circle-progress-01"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Fournisseur inscrit</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={globalProviderCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#fce152"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={60}
                          id="circle-progress-02"
                        >
                          <svg
                            className=""
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Comande annulees</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={globalCancelCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Row>
          </Col>
          <Col md="12" lg="12">
            <Row className="row-cols-1">
              <div className="overflow-hidden d-slider1 ">
                <Swiper
                  className="p-0 m-0 mb-2 list-inline "
                  slidesPerView={4}
                  spaceBetween={32}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 3 },
                    1400: { slidesPerView: 4 },
                    1500: { slidesPerView: 5 },
                    1920: { slidesPerView: 6 },
                    2040: { slidesPerView: 7 },
                    2440: { slidesPerView: 8 },
                  }}
                >
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={70}
                          id="circle-progress-03"
                        >
                          <svg className="" width="24" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Commande en cours</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={globalInprogressCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={70}
                          id="circle-progress-03"
                        >
                          <svg className="" width="24" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Client injoignable</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={globalCustomerUnreachableCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" card card-slide">
                    <div className="card-body">
                      <div className="progress-widget">
                        <Circularprogressbar
                          stroke={"#000000"}
                          width="60px"
                          height="60px"
                          trailstroke="#ddd"
                          strokewidth="4px"
                          Linecap="rounded"
                          style={{ width: 45, height: 45 }}
                          value={70}
                          id="circle-progress-03"
                        >
                          <svg className="" width="24" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z"
                            />
                          </svg>
                        </Circularprogressbar>
                        <div className="progress-detail">
                          <p className="mb-2">Commande livre</p>
                          <h4 className="counter">
                            <CountUp
                              start={0}
                              end={globalEndedCoursesCount}
                              duration={1}
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currrentUser: state.userInfoReducer.addedUserInfos,
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
