import React from "react";
import Moment from "react-moment";
// react-bootstrap components
// import { Card, Table, Container, Row, Col } from 'react-bootstrap'
import {
  Card,
  Table,
  Button,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FaEdit,
  FaTrash,
  FaCog,
  FaPowerOff,
  FaEye,
  FaPlus,
  FaWallet,
  FaMoneyBill,
  FaMoneyCheck,
  FaStar,
  FaStarHalfAlt,
  FaStarHalf,
} from "react-icons/fa";
import { Modal } from "rsuite";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import deliver from "../../../restApiService/auth";
import Empty from "../../../components/Empty/Empty";

class Provider extends React.Component {
  state = {
    item: [],
    dataList: [],
    entityDataTmp: [],
    selectedUser: [],
    selectedId: 0,
    livo_wallets: [],
    createModalIsOpen: false,
    editModalIsOpen: false,
    majModalIsOpen: false,
    deleteModalIsOpen: false,
    dataSending: false,
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    let item = { ...this.state.item };
    item[name] = value;
    this.setState({ item });
  };

  handleSearch = (name, event) => {
    let type = name;
    console.log("event.target.value.length", event.target.value.length);

    if (name == "full-name") {
      if (event.target.value.length >= 2) {
        this.setState({
          dataList: this.state.entityDataTmp.filter(
            (item) =>
              item.first_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase()) ||
              item.last_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
          ),
        });
      }
      if (event.target.value.length === 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    } else if (name == "createdAt") {
      if (event.target.value.length >= 10) {
        this.setState({
          dataList: this.state.entityDataTmp.filter((item) =>
            item.createdAt
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          ),
        });
      }
      if (event.target.value.length === 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    }
  };

  handleNumber = (name, event) => {
    let type = name;
    if (event.target.value.length >= 2) {
      const conformData = this.state.entityDataTmp.filter(
        (data) => data?.contact
      );
      this.setState({
        dataList: conformData.filter((item) =>
          item?.contact.toLowerCase().includes(event.target.value.toLowerCase())
        ),
      });
    }
    if (event.target.value.length === 0) {
      this.setState({
        dataList: this.state.entityDataTmp,
      });
    }
  };

  editModal = (item) => {
    this.setState({
      item: item,
      editModalIsOpen: true,
    });
  };

  entityList() {
    const { token } = this.props.currrentUser;
    deliver
      .userInfoList(token)
      .then((req) =>
        this.setState({ dataList: req.data, entityDataTmp: req.data })
      );
  }

  deleteModal = (item) => {
    this.setState({
      item: item,
      deleteModalIsOpen: true,
    });
  };

  updateUserInfo = () => {
    const { token } = this.props.currrentUser;
    deliver
      .updateUserAccount(this.state.item.id, this.state.item, token)
      .then((res) => {
        this.componentDidMount();
        this.setState({ editModalIsOpen: false });
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  updateUserStatus = (itemData, status) => {
    const { token } = this.props.currrentUser;
    // const promiseVar = toast.loading('Traitement en cours...')
    let data = {
      email: itemData.email,
      status: status,
    };
    deliver
      .updateUserAccount(itemData.id, data, token)
      .then((res) => {
        this.componentDidMount();
        // toast.update(promiseVar, {
        //   render: 'Opération éffectuée avec succès',
        //   type: 'success',
        //   isLoading: false,
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'colored',
        // })
        toast.success("Opération éffectuée avec succès", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  deleteUser = () => {
    const { token } = this.props.currrentUser;
    deliver
      .deleteUserAccount(this.state.item.id, token)
      .then((res) => {
        this.componentDidMount();
        this.setState({ deleteModalIsOpen: false });
        toast.success("Opération éffectuée avec succès", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  componentDidMount() {
    console.log(new Date("2024-01-01"));
    this.entityList();
  }

  render() {
    const hideModal = () => {
      this.setState({
        createModalIsOpen: false,
        editModalIsOpen: false,
        majModalIsOpen: false,
        deleteModalIsOpen: false,
      });
    };
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Listes des fournisseurs</Card.Title>
                  {/* <p className="card-category">
                    Here is a subtitle for this table
                  </p> */}
                </Card.Header>
                <Card.Body className="table-full-width table-responsive p-0">
                  <div className="setting-box d-flex p-4">
                    <div className="form-item mr-4">
                      <label>Nom / prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(event) =>
                          this.handleSearch("full-name", event)
                        }
                      />
                    </div>
                    <div className="form-item mr-4">
                      <label>Numero de téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(event) =>
                          this.handleNumber("contact", event)
                        }
                      />
                    </div>
                    <div className="form-item mr-4">
                      <label>Status</label>
                      <select
                        className="form-control"
                        onChange={(event) => this.handleSearch("status", event)}
                      >
                        <option value={0}></option>
                        <option value={1}>En cours</option>
                        <option value={2}>Annulé</option>
                        <option value={3}>Terminé</option>
                      </select>
                    </div>
                    <div className="form-item">
                      <label>Date d'inscription</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(event) =>
                          this.handleSearch("createdAt", event)
                        }
                      />
                    </div>
                  </div>
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">Nom et prenom</th>
                        <th className="border-0">Contact</th>
                        <th className="border-0">Entreprise</th>
                        <th className="border-0 text-center-important">
                          publiés
                        </th>
                        <th className="border-0 text-center-important">
                          annulées
                        </th>
                        <th className="border-0">Status</th>
                        <th>Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataList.map((data, index) => {
                        const deleted = data?.provider?.filter(
                          (item) => item.status == 2
                        );
                        const open = data.livoProfilTypeId == 1 && (
                          <tr key={index}>
                            <td>
                              {data.last_name} {data.first_name}
                            </td>
                            <td>{data.contact}</td>
                            <td>{data.bussiness_name}</td>
                            <td className="text-center">
                              {data?.provider?.length}
                            </td>
                            <td className="text-center">{deleted?.length}</td>
                            <td>{data.status == null ? "Inactif" : "Actif"}</td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {data.createdAt}
                              </Moment>
                            </td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-488980961">
                                    Editer
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="action-btn rounded-full"
                                  onClick={() => this.editModal(data)}
                                >
                                  <FaEdit />
                                </button>
                              </OverlayTrigger>
                              <button
                                className="action-btn rounded-full"
                                onClick={() => this.deleteModal(data)}
                              >
                                <FaTrash />
                              </button>
                              {data.status === 1 ? (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-488980961">
                                      Désactiver
                                    </Tooltip>
                                  }
                                >
                                  <button
                                    className="bg-danger action-btn text-white rounded-full"
                                    onClick={() =>
                                      this.updateUserStatus(data, 2)
                                    }
                                  >
                                    <FaPowerOff />
                                  </button>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-488980961">
                                      Activer
                                    </Tooltip>
                                  }
                                >
                                  <button
                                    className="action-btn rounded-full"
                                    onClick={() =>
                                      this.updateUserStatus(data, 1)
                                    }
                                  >
                                    <FaPowerOff />
                                  </button>
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        );
                        return open;
                      })}
                    </tbody>
                  </Table>
                  {this.state.dataList.length < 1 && <Empty />}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal open={this.state.editModalIsOpen} onClose={hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>LIVO ADMIN | Editer un utilisateur</Modal.Title>
          </Modal.Header>
          <Modal.Body className="term">
            <div className=" row p-4 text-justify">
              <div className="col-md-12"></div>

              <div className="col-md-3">
                <div className="form-group mb-3">
                  <label for="civility">
                    Civilité <span className="text-red">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="civility"
                    id="civility"
                    value={this.state.item.civility}
                    onChange={this.handleChange}
                  >
                    <option>Sélectionnez...</option>
                    <option value="M">M.</option>
                    <option value="Mme">Mme</option>
                    <option value="Mlle">Mlle</option>
                  </select>
                </div>
              </div>

              <div className="col-md-9">
                <div className="form-group mb-3">
                  <label for="last_name">
                    Nom <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control ToUpperCase"
                    placeholder="Entrez votre nom"
                    name="last_name"
                    id="last_name"
                    value={this.state.item.last_name}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label for="last_name">
                    Prénom <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control ToCapitalizeCase"
                    placeholder="Entrez votre prénom"
                    name="first_name"
                    id="first_name"
                    value={this.state.item.first_name}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label for="email">
                    E-mail
                    <span className="text-red">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Entrez votre e-mail"
                    name="email"
                    id="email"
                    value={this.state.item.email}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label for="contact">
                    Contact
                    <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le contact"
                    name="contact"
                    id="contact"
                    value={this.state.item.contact}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label for="bussiness_name">
                    Entrez le nom de votre boutique (facultatif)
                    <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entrez le nom de votre boutique (facultatif)"
                    name="bussiness_name"
                    id="bussiness_name"
                    value={this.state.item.bussiness_name}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="form-group p-4 text-right w-100">
              {this.state.dataSending003 ? (
                <button className="st-btn-md st-bg-yellow text-black f-w-600 p-h-20">
                  Modification...
                </button>
              ) : (
                <button
                  className="st-btn-md st-bg-yellow text-black f-w-600 w-100 p-h-20"
                  onClick={this.updateUserInfo}
                >
                  Modifier
                </button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal open={this.state.deleteModalIsOpen} onClose={hideModal}>
          <Modal.Header>
            <Modal.Title>LIVO ADMIN | Suppression de compte</Modal.Title>
          </Modal.Header>
          <Modal.Body className="term">
            <div className="text-center">
              <p className="m-4">
                Voulez-vous supprimer le compte de{" "}
                <strong>
                  {this.state.item.first_name +
                    " " +
                    this.state.item.last_name ?? ""}
                </strong>{" "}
                ?
              </p>
              <button
                onClick={hideModal}
                className="st-btn-md st-bg-black text-white f-w-600 p-h-20 me-4"
              >
                Non
              </button>
              <button
                onClick={this.deleteUser}
                className="st-btn-md st-bg-yellow text-white f-w-600 p-h-20"
              >
                Oui
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    currrentUser: state.userInfoReducer.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Provider);
